import React from 'react';
import SEO from '../../components/common/seo';
import Layout from '../../components/common/layout';
import Banner from '../../components/common/hero';
import ServicesSvg from '../../assets/svgs/services.svg';

const render = <ServicesSvg />;

const IndexPage = () => {
  return (
    <>
      <SEO title="About us" description="A nice description about us." />
      <Layout>
        <Banner
          heading="Our solutions highlight our strengths"
          description="We're experts at what we do and we take it seriously"
          ctaText="Lets get started"
        >
          {render}
        </Banner>
      </Layout>
    </>
  );
};

export default IndexPage;
